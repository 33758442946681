// extracted by mini-css-extract-plugin
export var navbar = "navbar-module--navbar--H7hv2";
export var left = "navbar-module--left--4CAaQ";
export var logoContainer = "navbar-module--logoContainer--+8Byd";
export var navigation = "navbar-module--navigation--K-XRf";
export var link = "navbar-module--link--zq8Ui";
export var social = "navbar-module--social--7Q9Nt";
export var twitter = "navbar-module--twitter--sH18S";
export var opensea = "navbar-module--opensea--gM5hY";
export var instagram = "navbar-module--instagram--BbCHe";
export var mobile = "navbar-module--mobile--cv11S";
export var discordContainer = "navbar-module--discordContainer--4vnyX";
export var discordText = "navbar-module--discordText--+WpsZ";
export var discordHide = "navbar-module--discordHide--h+6Az";
export var discordLogo = "navbar-module--discordLogo--DYqwJ";
export var menuIcon = "navbar-module--menuIcon--qtYOA";
export var collapsed = "navbar-module--collapsed--RtM3S";