// extracted by mini-css-extract-plugin
export var container = "community-module--container--ADdTy";
export var contentContainer = "community-module--contentContainer--wxMVT";
export var socials = "community-module--socials--2Ttky";
export var showcaseContainer = "community-module--showcaseContainer--h6w4A";
export var title = "community-module--title--Noxoh";
export var firstParagraph = "community-module--firstParagraph--ksJeT";
export var secondParagraph = "community-module--secondParagraph--nx2Lx";
export var discordButton = "community-module--discordButton--P4ByK";
export var discordContainer = "community-module--discordContainer--jYaRF";
export var discordText = "community-module--discordText--8kQjL";
export var discordLogo = "community-module--discordLogo--pO-6R";
export var socialLink = "community-module--socialLink--tJftW";
export var socialIcon = "community-module--socialIcon--noU5y";
export var rock = "community-module--rock--1TTIh";
export var nebula = "community-module--nebula--Vy3r-";