// extracted by mini-css-extract-plugin
export var description = "description-module--description--p0J+o";
export var contentContainer = "description-module--contentContainer--4fhju";
export var showcaseContainer = "description-module--showcaseContainer--GVSpt";
export var imageContainer = "description-module--imageContainer--Am24B";
export var textContainer = "description-module--textContainer--sDceP";
export var contentParagraph = "description-module--contentParagraph--42KN9";
export var saleInfo = "description-module--saleInfo--XfKKL";
export var discordButton = "description-module--discordButton--s3cqU";
export var bannerMobile = "description-module--bannerMobile--xK+rI";
export var cta = "description-module--cta--6px9O";
export var bannerDesktop = "description-module--bannerDesktop--JtzpV";
export var nebula = "description-module--nebula--PAsay";