// extracted by mini-css-extract-plugin
export var container = "drop-module--container--u+RXr";
export var header = "drop-module--header--ONTo9";
export var stamp = "drop-module--stamp--HZu7t";
export var date = "drop-module--date--3SgIB";
export var number = "drop-module--number--e4Oqa";
export var image = "drop-module--image--lRUGL";
export var titleContainer = "drop-module--titleContainer--TExR4";
export var title = "drop-module--title--NuWTi";
export var body = "drop-module--body--ZPsKj";
export var inactive = "drop-module--inactive--Jgs+T";